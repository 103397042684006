import { useEffect, useRef } from "react";

export const FullScreenBorder = ({
  text,
  type,
}: {
  text: string;
  type: "green" | "sand";
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const colorType = {
    green: "bg-[#3C5043] text-[#E3DACC]",
    sand: "bg-[#C0A57E] text-[#141414]",
  };

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        ref.current.style.transform = `translateX(-${window.scrollY}px)`;
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);

  return (
    <div
      className={`${colorType[type]} overflow-hidden font-FKScreamer text-[46px] font-normal`}
    >
      <div
        ref={ref}
        className="flex h-[102px] w-full flex-row items-center justify-start gap-[42px]  whitespace-nowrap text-center uppercase"
      >
        <div>{text}</div>
        <div>{text}</div>
        <div>{text}</div>
        <div>{text}</div>
        <div>{text}</div>
        <div>{text}</div>
        <div>{text}</div>
        <div>{text}</div>
        <div>{text}</div>
      </div>
    </div>
  );
};
